// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");
import storage from '../../../framework/src/StorageProvider';

export interface Props {
  navigation: any;
  id: string;
}

interface AccountImage {
  type: string;
  filename: string;
  url: string;
  id: number;
}
export interface CardsDetails {
  id: string;
  attributes: JobAttributes;
  type: string;
}


export interface Role {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface RecentWorkExperience {
  position: string;
}

export interface Qualification {
  id: number;
  course: string;
  university: string;
  specialization: string;
  from_date: string;
  to_date: string;
}

export interface About {
  id: number;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface WorkExperience {
  id: number;
  organization: string;
  position: string;
  experience: number;
  start_date: string;
  end_date: string;
  job_responsibilities: string;
}

export interface Skill {
  id: number;
  name: string;
}

export interface TeachingInterest {
  id: number;
  name: string;
}

export interface File {
  id: number;
  url: string;
  type: string;
  filename: string;
  size: string;
}

export interface Certificate {
  id: number;
  name: string;
  start_date: string | null;
  end_date: string | null;
  files: File[] | null;
}

export interface Certifications {
  upload_resumes: string[];
  letters_of_interests: string[];
  credentials: string[];
  transcripts: string[];
  note_pages: string[];
  letter_of_reference_emails: string[];
  certificates: Certificate[];
}

export interface JobPreferences {
  preferred_modality_ids: number[];
  preferred_modality_names: string[];
  preferred_age_group_ids: number[];
  preferred_age_group_names: string[];
  preferred_working_days_ids: number[];
  preferred_working_days_names: string[];
  preferred_nature_of_jobs_ids: number[];
  preferred_nature_of_jobs_names: string[];
  preferred_time_shifts_ids: number[];
  preferred_time_shifts_names: string[];
  document_sharings_ids: number[];
  document_sharings_names: string[];
  preferred_travel_distance: number;
}

export interface ProfileImage {
  id: number;
  url: string;
  type: string;
  filename: string;
  size: string;
}

interface WorkingDayTime {
  id: number;
  working_day_id: number;
  start_time: string;
  end_time: string;
  full_day: boolean;
}

export interface AccountAttributes {
  activated: boolean;
  email: string;
  preferred_modality_ids: number[];
  full_phone_number: string;
  last_name: string;
  state: string;
  member_id: string | null;
  password: string | null;
  street_address: string;
  country: string;
  organization: string | null;
  no_of_employees: number | null;
  id: number;
  preferred_age_group_name: string[];
  program_type: string | null;
  account_working_day_times: WorkingDayTime[];
  first_name: string;
  city: string;
  credentials: string;
  preferred_travel_distance: number;
  program_capacity: number | null;
  about_us: string | null;
  allow_sms_notification: boolean;
  zip_code: number;
  upload_resumes: string;
  note_pages: string;
  sms_recommendation: boolean;
  transcripts: string;
  total_experience: number;
  role: Role;
  profile_image: ProfileImage;
  preferred_time_shifts_ids: number[];
  letters_of_interests: string;
  preferred_nature_of_jobs_name: string[];
  certifications: Certifications;
  letter_of_reference_emails: string;
  country_code: string | null;
  phone_number: string | null;
  preferred_working_days_ids: number[];
  job_preferences: JobPreferences;
  preferred_working_days_name: string[];
  skills: Skill[];
  preferred_nature_of_jobs_ids: number[];
  document_sharings_ids: number[];
  about: About;
  document_sharings_name: string[];
  recent_work_experience: RecentWorkExperience;
  created_at: string;
  work_experiences: WorkExperience[];
  preferred_modality_name: string[];
  qualifications: Qualification[];
  preferred_age_group_ids: number[];
  teaching_interests: TeachingInterest[];
  preferred_time_shifts_name: string[];
}

export interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

export interface AccountData {
  data: Account[];
}
interface JobAttributes {
  account_image: AccountImage;
  organization: string;
  job_title: string;
  job_description: string;
  state: string;
  country: string;
  id: string;
  posted_at: string;
  key_responsibilities: string;
  street_address: string;
  experience_required: string;
  city: string;
  qualifications: string;
  zip_code: string;
  post_created_at: string;
  about_organization: string;
  job_applied_at: string | null;
  admin_email: string;
  location: string;
  role_name: string;
  education_level: string;
  job_image: string;
  benefits: string;
  posted_by: string;
  created_at: string;
}


interface S {
  cardsDetails:CardsDetails[];
  isRemoved:boolean;
  role:string,
  teacherDetails:Account[],
  removedMessage:string,
  from :string;
  isDrawerOpen:boolean;
  loader:boolean;
}

interface SS {
  id: string;
}


export const configJSON = require("./config");

export default class SavedJobController extends BlockComponent<Props, S, SS> {
  getSavedlistId:string='';
  unsavejobID:string='';
  unsaveTeacherID:  string='';
  getSavedlistIdTeacher:string=''
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      cardsDetails: [],
      isRemoved:false,
      role:'',
      teacherDetails:[],
      removedMessage:'',
      from : "",
      isDrawerOpen:false,
      loader:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    const apiRequestCallId=message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));     
      if(this.getSavedlistIdTeacher==apiRequestCallId){
        this.setState({loader:true})
        this.setState({teacherDetails:responseJson?.data })
        if (responseJson?.data) {
          this.setState({loader:false})
        }
      }
      if(this.getSavedlistId==apiRequestCallId){
        this.setState({loader:true})
       this.setState({cardsDetails:responseJson?.data})
       if (responseJson?.data) {
         this.setState({loader:false})
       }
      }
     if(this.unsaveTeacherID==apiRequestCallId){
      this.setState({removedMessage:'Teacher removed Successfully!'})
      this.getSavedListTeacher()
     }
    }
  }

  handleBack = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleRemoveCard = (index:number,cardeIndex:string) => {
    this.unSaveJob(cardeIndex)
    this.setState({isRemoved:true})
    this.setState(prevState => {
      const updatedCardsDetails = [...prevState.cardsDetails];
      updatedCardsDetails.splice(index, 1);
      return { cardsDetails: updatedCardsDetails };
    })
  }
  closeSnackbar=()=>{
    this.setState({isRemoved:false})
  }
  async componentDidMount() {
    this.setState({
      from : await storage.get("from")
    })
    super.componentDidMount();
    this.setState({loader:true})
    this.getSavedList()
    this.getSavedListTeacher()
    this.setState({
      role:await storage.get("role")
    })
  }
  getSavedList=async ()=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
    const setIntroMsg = new Message( getName(MessageEnum.RestAPIRequestMessage));
     this.getSavedlistId = setIntroMsg.messageId;

    setIntroMsg.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_joblisting/job_creations/saved_jobs` );

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET" );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  unSaveJob=async (index:string)=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unsavejobID = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/${index}/unsave`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  shortString=(value:string)=>{
      if (value.length <= 100) {
        return value;
      }return value.slice(0, 140) + '...';
  }
   viewDetailPage = async (index: string)  => {
    if(await storage.get("loginToken")){
      storage.set('jobId',index)
      storage.set('profileId',index)
      storage.set('from',true)
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'JobListingDetails'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  };

  getSavedListTeacher=async ()=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    

    this.getSavedlistIdTeacher = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/saved_substitute_teachers`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  removeTeacher = async (id:number , endpoint:string)=>{
    this.unsaveTeacherID = await this.sendUnsaveRequest(id ,endpoint)
  }

  sendUnsaveRequest = async (id: number, endpoint: string) => {
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
  
    const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const messageId = setIntroMsg.messageId;
  
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${id}/${endpoint}`
    );
  
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  
    return messageId;
  };

  toggleDrawerOpen = (isOpen: boolean) => {
    this.setState({ 
      isDrawerOpen: isOpen
    });
  };
}

// Customizable Area End
