import React from 'react';
import { Box, styled, Typography } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

interface Time {
  hour: number | string;
  minute: number | string;
  period: 'AM' | 'PM';
}

interface TimeDisplayProps {
  label: string;
  time: Time | undefined;
  onClick: () => void;
  testId: string;
  clearTime : () => void;
}

const formatTime = (value: number | string) => {
  return value.toString().padStart(2, '0');
};

const TimeDisplay: React.FC<TimeDisplayProps> = ({ label, time, onClick, testId  ,clearTime}) => {
  const getHour = ()=>{
    if (time?.hour) {
      return time.hour
    }
    if (time?.hour === 0 ) {
     return time.hour 
    }
    if (time?.hour !== 0 && !time?.hour && label === 'Start time') {
      return 9
    }else {return 5}
  }
  const hour = time?.hour || (label === 'Start time' ? 9 : 5)
  return (
  <Box data-test-id={testId}  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' ,width:"100%" }}>
    <Typography style={webStyle.prefOption}>{label}</Typography>
    <div style={{display:"flex" , alignItems:"center" ,justifyContent:"flex-end", gap:"5px",width:"100%"}}>
    <Typography onClick={onClick}style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' , cursor:"pointer" , }}>
      {`${formatTime(getHour())}:${formatTime(time?.minute || '00')} ${time?.period || (label === 'Start time' ? 'AM' : 'PM')}`}
    </Typography>
    <ClearButton data-test-id ='clearTime' onClick={clearTime}>
    <ClearRoundedIcon  style={{color:"white"}}/>
    </ClearButton>
    </div>
  </Box>
)
}

export default TimeDisplay;

const ClearButton = styled(Box)({
  maxWidth:"25px" , height:"25px" , display:"flex" ,
  width  :"100%",
  borderRadius : "6px",
  background:"rgb(57, 81, 105)"
})
const webStyle={
    prefOption: {
        color: '#1E293B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        width:"100%"
      },
}