// Customizable Area Start
import React from "react";
import SavedJobController ,{Account} from "./SavedJobController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import { Box, Grid, Typography, styled,Snackbar, Button } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { description, image, location, noResult, savedStarIcon, tickIcon ,profileDummy , notFound} from "./assets";
import MenuIcon from '@material-ui/icons/Menu'
import DrawerComponent from "../../../components/src/Drawer.web";
import Loader from "../../../components/src/Loader";
// Customizable Area End

class SavedJob extends SavedJobController {
  // Customizable Area Start
  renderProfileImage(src:string) {
    return (
      <Box sx={webStyle.imageProfile}>
        <img
          src={src || profileDummy}
          data-test-id="profileImageTestiD"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = notFound;
          }}
          alt={src}
          style={{ aspectRatio: "1", width: '100%', height: 'auto', borderRadius: "50%" }}
        />
      </Box>
    );
  }

  renderTeacherDetails(item:Account) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <Typography style={webStyle.teacherTitle}>
          {item.attributes?.first_name} {item.attributes?.last_name}
        </Typography>
        <Typography style={{ ...webStyle.seconddiv, color: "#64748B", marginTop: "10px", fontSize: "16px" }}>
          {item.attributes?.recent_work_experience?.position} Substitute Teacher
        </Typography>
      </div>
    );
  }

  renderLocationInfo(item:Account) {
    return (
      <Box style={{ ...webStyle.newdiv, gap: "15px", flexWrap: "wrap" ,marginTop:"0" , paddingTop:"0" , width:"100%"}}>
        <Box>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div style={{ width: "20.67px", height: "16.67px" }}>
              <img src={location} style={{ width: "100%" }} />
            </div>
            <Typography style={{ ...webStyle.anotherdiv }}>
              {item.attributes?.city}, {item.attributes?.state}, {item.attributes?.country}
            </Typography>
          </div>
        </Box>
      </Box>
    );
  }

  renderNoListingShowing() {
    if (this.state.loader && 
      this.state.cardsDetails?.length == 0 && 
      this.state.teacherDetails?.length == 0) {
      return  <Loader loading={this.state.loader}/>
    }
    else{
      return (
        <>
          {((this.state.role == '2' && this.state.cardsDetails?.length == 0 && !this.state.loader) || (this.state.role == '1' && this.state.teacherDetails?.length == 0 && !this.state.loader)) && (
            <Box style={webStyle.noResultBox}>
              <Box>
                <img src={noResult} />
                <Typography style={webStyle.noResultText}>No data found!</Typography>
              </Box>
            </Box>
          )}
        </>
      ) 
    }
  }

  renderTeacherListing() {
    const { teacherDetails } = this.state;
    
    return (
      <>
        {teacherDetails?.length > 0 && (
          teacherDetails.map((item,index) => (
              <div style={{position:'relative'}} key={index}>
              <div style={webStyle.cards}>
                  <div style={webStyle.ContainerStyleTeacher}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
                    {this.renderProfileImage(item.attributes?.profile_image?.url)}
                    </div>
                    <Box style={{display:"flex" , width:"100%" , flexWrap:"wrap"}}>
                  {this.renderTeacherDetails(item)}
                      <div style={{ marginTop: "17px" ,display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" , flexWrap:"wrap" , gap:"15px" }}>
                        <div style={{ display: "flex" , justifyContent:"right" ,width: "100%" }}>
                          <div style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                            <WorkOutlineIcon style={{color:"#64748B",fontSize:"20px" , marginRight:"9px"}}/>
                            <Typography style={{ ...webStyle.smallText }}>{item.attributes.total_experience}</Typography>
                          </div>
                        </div>
                      </div>
                      </Box>
                    </div>
                  <ResponsiveBox  style={{ display: "flex", justifyContent: "space-between" , marginTop:"35px" , gap:"10px"}}>
                {this.renderLocationInfo(item)}
                    <Box style={{display:"flex" , justifyContent: "right",alignItems:"center" , width:"100%"}}>
                    <ViewDetail item sm={6} style={{ display: "flex", gap: '4px' , marginRight:"12px" , cursor:"pointer" }} data-test-id="removeSaved" onClick={()=>this.removeTeacher(item?.attributes?.id , 'unsave_substitute_teacher')}>
                        <img src={savedStarIcon} alt="" style={{width:'21px',height:'21px'}}/>
                        <Typography style={webStyle.Saved}>Saved</Typography>
                      </ViewDetail>
                    <Button style={webStyle.buttonStyle} data-test-id="viewDetailPageTeacher" 
                    onClick={() => this.viewDetailPage(item.id)}
                    >View details</Button>
                  </Box>
                </ResponsiveBox>
              </div>
              </div>
          )))}</>
    )
  }
  title = ()=>{
    return this.state.role == '1' ? "Teachers" :"Jobs"
  }
  render() {
    return (
      <>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation} id={""} NaviagationPage={"savedjob"} />
        </CustomNavbar>
        <NavbarMobile>
          <ArrowBackIos data-test-id='btnBack' onClick={this.handleBack} style={webStyle.backIcon} />
          <NavbarTitle>Saved {this.title()}</NavbarTitle>
          <MenuIcon
          style={{ color: '#fff', position: 'absolute', right: '47px',cursor:'pointer' }}
          onClick={() => this.toggleDrawerOpen(true)}
          data-test-id="toggleDrawer"
        />
          <DrawerComponent
          isOpen={this.state.isDrawerOpen}
          toggleDrawer={this.toggleDrawerOpen} 
          navigation={this.props.navigation}        
          />
        </NavbarMobile>
        <MainBox>
          <Navigation>
            <Typography data-test-id="navigateToHome" onClick={this.handleBack} style={webStyle.home}>Home</Typography>
            <ArrowForwardIos style={webStyle.forwardArrow} />
            <Typography style={webStyle.joblisting}>Saved {this.title()}</Typography>
          </Navigation>
          <Heading>Saved {this.title()} by you</Heading>
          <CustomSnackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.isRemoved}
              autoHideDuration={5000}
              data-test-id='closeSnackbar'
              onClose={this.closeSnackbar}
            >
              <div style={webStyle.popup as React.CSSProperties}>
                <img style={{marginRight: '10px'}} src={tickIcon} alt="" />
                { this.state.removedMessage || 'Job Post removed from Saved Jobs!' }
              </div>
          </CustomSnackbar>
          <Box style={{position:'relative' , minHeight:"50vh" , marginTop:"28px" , marginBottom:"70px"}}>
            {this.state.role == '2' && this.state.cardsDetails?.map((item,index) => {
              return (
                <>
                  <div style={webStyle.cards}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <CardTitle>
                        {item.attributes.job_title}
                      </CardTitle>
                      <PostedAtTypo>
                        <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }}>Posted:</Typography>
                        <Typography style={webStyle.typeStyle}>{item.attributes.created_at}</Typography>
                      </PostedAtTypo>
                    </div>
                    <div style={webStyle.customdivStyle}>
                      <Typography style={webStyle.thirddiv}>{item.attributes.experience_required}</Typography>
                      <Typography style={webStyle.thirddiv}>{item.attributes.education_level}</Typography>
                    </div>
                    <div style={webStyle.ContainerStyle}>
                      <div>
                        <img src={image} />
                      </div>
                      <div>
                        <Typography style={webStyle.seconddiv}>{item.attributes.organization}</Typography>
                        <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }} >{item.attributes.posted_by} . School admin</Typography>
                      </div>
                    </div>
                    <div style={{ 
                      display: "flex", 
                      gap: "10px", 
                      marginTop: "24px" , 
                       }}>
                      <div>
                        <img src={description} />
                      </div>
                      <div>
                        <Typography style={{ ...webStyle.seconddiv, color: "#475569",textWrap:"pretty",marginTop:"-12px" }}>
                        <div style={{wordBreak:"break-all" , 
                        textWrap:"wrap" , wordWrap:"break-word"}}
                         dangerouslySetInnerHTML={{ __html: this.shortString(item.attributes.job_description) }} />
                        </Typography>
                      </div>
                    </div>
                    <CustomGrid >
                      <Grid item sm={6}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div style={{ width: "20.67px", height: "16.67px" }}>
                            <img src={location} style={{ width: "100%" }} />
                          </div>
                          <Typography style={webStyle.anotherdiv}>{item.attributes.city}, {item.attributes.state}, {item.attributes.country}</Typography>
                        </div>
                      </Grid>
                      <Box style={{display:"flex" , justifyContent:"right" , gap:"12px" , alignItems:"center" }}>
                      <ViewDetail item sm={6} style={{ display: "flex", gap: '4px' , cursor:"pointer"}} data-test-id="handleRemoveCard"  onClick={() => this.handleRemoveCard(index,item.attributes.id)}>
                        <img src={savedStarIcon} alt="" style={{width:'21px',height:'21px'}}/>
                        <Typography style={webStyle.Saved}>Saved</Typography>
                      </ViewDetail>
                      <Button style={webStyle.buttonStyle} data-test-id="viewDetailPage"
                      onClick={() => this.viewDetailPage(item.attributes?.id)}>View details</Button>
                      </Box>
                    </CustomGrid>
                  </div>
                </>
              )
            })}
            {this.state.role == '1' && this.renderTeacherListing()}
           {this.renderNoListingShowing()}
          </Box>
        </MainBox>
        <Footer navigation={this.props.navigation} id={""} NaviagationPage={"savedjob"} />
      </>
    );
  }
  // Customizable Area End
}

export default SavedJob;
// Customizable Area Start
const role = localStorage.getItem("role");
const width = role == '1' ? 850 : 571 
const CustomNavbar = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(width)]: {
    display: 'none',
  },
}));

const NavbarMobile = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down(width)  ]: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: '15px 0 15px 20px',
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: 1,
  },
}));

const CustomGrid = styled(Box)({
  borderTop: "1px solid #F1F5F9",
  display: "flex",
  justifyContent: "space-between",
  marginTop:'0px' , paddingTop:"12px" , gap:"10px",
  "@media only screen and (max-width: 571px)": {
    flexDirection:"column"
  },
})

const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 375px 120px 375px",
  "@media only screen and (max-width: 1330px)": {
    paddingTop: 100,
    paddingLeft: 300,
    paddingRight: 300
  },
  "@media only screen and (max-width: 1235px)": {
    paddingTop: 100,
    paddingLeft: 230,
    paddingRight: 230
  },
  "@media only screen and (max-width: 1100px)": {
    paddingTop: 100,
    paddingLeft: 185,
    paddingRight: 185
  },
  "@media only screen and (max-width: 959px)": {
    paddingTop: 100,
    padding: 100,
  },
  "@media only screen and (max-width: 664px)": {
    padding: 37,
    paddingTop: 100
  },
  "@media only screen and (max-width: 571px)": {
    padding: 0,
    background: '#fff',
    paddingTop: 20
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})

const ResponsiveBox = styled(Box)({
  display: "flex", justifyContent: "space-between" , marginTop:"35px",
  "@media only screen and (max-width: 571px)": {
    flexWrap:"wrap",
  }
})
const Heading = styled(Typography)({
  color: '#1E293B',
  fontFamily: "Inter",
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '40px',
  marginBottom: 24,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const PostedAtTypo = styled(Box)({
  display: "flex", gap: "5px",
  "@media only screen and (max-width: 375px)": {
    display: 'none'
  }
})
const CardTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "26px",
  color: "0F172A",
  "@media only screen and (max-width: 414px)": {
    fontSize: 14
  }
})
const ViewDetail = styled(Grid)({
  display: "flex", justifyContent: "flex-end",
  "@media only screen and (max-width: 414px)": {
    display: 'none'
  }
})
const CustomSnackbar=styled(Snackbar)({
  boxShadow: "0px 6px 15px -3px #00000026",
  borderRadius:8,
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:158
  }
})
const webStyle = {
  backIcon: {
    color: '#fff'
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  cards: {
    padding: "32px",
    background: "#FFFFFF",
    borderRadius: "16px",
    marginBottom: "24px"
  },
  seconddiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  thirddiv: {
    backgroundColor: "#F8F8F8",
    padding: "2px 12px 2px 12px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
    borderRadius: "2px"
  },
  fourthdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  customdivStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "12px",
    borderRadius: "2px"
  },
  ContainerStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "20px"
  },
  typeStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#4788B7"
  },
  buttonStyle: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#395169",
    border: "1px solid #395169",
    padding: "6px 16px 6px 16px",
    borderRadius: "8px",
    width:"100%",
    textTransform: "none" as "none",
    maxWidth:"150px"
  },
  newdiv: {
    borderTop: "1px solid #F1F5F9",
    paddingTop: '20px',
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-between"
  },
  anotherdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  Saved: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left' as 'left',
  },
  popup: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '332px',
    padding: '10px 10px 10px 10px',
    gap: '8px',
    borderFadius: '8px',
    boxShadow: '0px 6px 15px -3px #00000026',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  },
  noResultBox: {
    background: "#fff",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center" as "center",
    alignItems: "center" as "center",
    paddingTop: 88,
    paddingBottom: 128,
    height:'56vh'
  },
  noResultText: {
    color: "0F172A",
    fontWeight: 600,
    marginTop: "16px",
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "28px",
  },
  smallText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
  },
  teacherTitle:{
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing :"-0.5%"
  },
  ContainerStyleTeacher: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    width:"100%"
  },
  appliedButton:{
    background : "#49D858",
    borderRadius : "2px",
    border:"none",
    outline:"none",
    width:"100%",
    maxWidth :"82px",
    height : "28px",
    color :"#FFFFFF"
  },
  imageProfile: {
    width:"127px",
    height:"127px",
    "@media (max-width:768px)": {
      width: "80px",
      height: "80px"
    } 
  }
};
// Customizable Area End