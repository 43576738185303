import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
export const configJSON = require("./config");
import { checkboxChecked, checkboxConditionFalse, checkboxConditionTrue, checkboxunChecked, pccLogo, visibleOff, wholeBackgroundImage, wholeBackgroundImageforMobile } from "./assets";
import { AlertBox, EmailInputWrapper, EnterEmailAndPassTxtTypography, ErrorMessage, InputField, InputsLabel, PasswordInputWrapper, WebLogoImage } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { VisibilityOff} from '@material-ui/icons'
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlePress(event:React.KeyboardEvent<HTMLElement>){
    if (event.key === "Enter") { this.createAccount(); }}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { passwordConditions, emailErrorMsg, passwordErrorMsg } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainContainerBox>
          <Box style={webStyle.innerContainer}>
            <WebLogoImage image={pccLogo} />
            <WelcomeSignUpTextTypography>
              {configJSON.signupText}
            </WelcomeSignUpTextTypography>
            <EnterEmailAndPassTxtTypography>
              {configJSON.enterEmailAndPasswordTxt} {configJSON.passTxt}{configJSON.wordTxt} {configJSON.toContinueTxt}
            </EnterEmailAndPassTxtTypography>
            {this.state.showAlert && <AlertBox>{this.state.alertMsg}</AlertBox>}
            <Box>
              <EmailInputWrapper>
                <InputsLabel>{configJSON.emailTxt}</InputsLabel>
                <InputField
                  data-test-id="txtInputEmail"
                  placeholder={configJSON.emailPlaceholderTxt}
                  type="email"
                  variant='outlined'
                  value={this.state.email}
                  onKeyPress={(event) => {
                    this.handlePress(event)}}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleEmailChange(event.target.value)}
                  error={!!emailErrorMsg}
                  required
                />
                {emailErrorMsg && <ErrorMessage>
                        {emailErrorMsg}
                </ErrorMessage>}
              </EmailInputWrapper>
              <PasswordInputWrapper>
                <InputsLabel>{configJSON.passTxt}{configJSON.wordTxt}</InputsLabel>
                <InputField
                  data-test-id="txtInputPassword"
                  type={this.state.enablePasswordField ? "password" : "text"}
                  placeholder={`${configJSON.enterTxt} ${configJSON.passTxt}${configJSON.wordTxt}`}
                  variant="outlined"
                  onKeyPress={(event) => {
                    this.handlePress(event)}
                  }
                  value={this.state.password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={this.handleClickShowPassword}
                        >
                          {!this.state.enablePasswordField ? (
                          <Visibility style={webStyle.eyeIcon} />
                        ) : (
                          <VisibilityOff style={webStyle.eyeIcon} />
                        )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!passwordErrorMsg}
                  required
                />
                {passwordErrorMsg && <ErrorMessage>
                        {passwordErrorMsg}
                </ErrorMessage>}
              </PasswordInputWrapper>
              <PasswordConditionTitleTypograpy>
                {configJSON.passTxt}{configJSON.wordTxt} {configJSON.mustIncludeTxt}
              </PasswordConditionTitleTypograpy>
              <PasswordConditionWrapper>
                <img style={webStyle.checkboxIcon} src={passwordConditions.isCapital ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  {configJSON.passConditionCapitalTxt}
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

              <PasswordConditionWrapper >
                <img style={webStyle.checkboxIcon} src={passwordConditions.isLowercase ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  {configJSON.passConditionLowerTxt}
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

              <PasswordConditionWrapper >
                <img style={webStyle.checkboxIcon} src={passwordConditions.isNumber ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  {configJSON.passConditionNumberTxt}
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

              <PasswordConditionWrapper >
                <img style={webStyle.checkboxIcon} src={passwordConditions.isLength ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  {configJSON.passConditionLengthTxt}
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

                <PolicyWrapper>
                  {
                      this.state.checkedPrivacyAndTerms ? 
                      <img data-test-id={"btnCheckedPrivacyAndTerms"} onClick={() => this.handleCheckboxChange(!this.state.checkedPrivacyAndTerms)}
                      style={webStyle.rememberCheckBox}
                       src={checkboxChecked} alt="checkboxChecked" /> 
                      : <img data-test-id={"btnCheckedPrivacyAndTerms"} onClick={() => this.handleCheckboxChange(!this.state.checkedPrivacyAndTerms)}
                      style={webStyle.rememberCheckBox}
                      src={checkboxunChecked} alt="checkboxunChecked" />
                    }
                  <Box>
                  <Typography>
                    {configJSON.policyAndTermsCheckboxTxt}&nbsp;
                    
                    <Typography onClick={() => this.navigateToPrivacyPolicy('privacy')} style={webStyle.privacyPolicyAndTerms} data-test-id={"btnGoToPrivacyPolicy"}>
                      {configJSON.privacyPolicyTxt}&nbsp;
                    </Typography>

                    {configJSON.andTxt}&nbsp;

                    <Typography onClick={() => this.goToTermsAndCondition()} style={webStyle.privacyPolicyAndTerms} data-test-id={"btnGoToTermsAndCondition"}>
                      {configJSON.termsOfUseTxt}
                    </Typography>
                    {configJSON.dotTxt}
                    </Typography>
                  </Box>

                </PolicyWrapper>
              <Box>
                <CreateAccountButton
                  data-test-id={"btnCreateAccount"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => this.createAccount()}
                >
                  {configJSON.createAccountTxt}
                </CreateAccountButton>
              </Box>
              <AlreadyHaveAccountWrapper>
                {configJSON.aleadyHaveAccountTxt}
                <LoginTextTypography
                  data-test-id="btnSocialLogin"
                  onClick={() => this.goToLoginPage()}
                >
                  {configJSON.alreadyhaveAccountloginText}
                </LoginTextTypography>
              </AlreadyHaveAccountWrapper>
            </Box>
          </Box>
        </MainContainerBox>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyle = {

  eyeIcon: {
    height: '24px',
    color: '#94A3B8',
    width: '24px',
  },
  checkedPrivacyAndTerms: {
    color: '#395169',
    marginRight: '8px',
    padding: '0px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    maxWidth: '440px',
    padding: '0 28px',
  },
  checkboxIcon: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
  forgetPasswordBox: {
    color: '#395169',
    fontSize: '14px',
    cursor: 'pointer',
    fontFamily: 'Inter',
    'text-transform': 'capitalize',
    'font-weight': '700',
  },
  privacyPolicyAndTerms: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    color: '#395169',
    cursor: 'pointer',
    display: 'inline',
  },
  rememberCheckBox: {
    padding: '0px',
    marginRight: '8px',
    color: '#395169',
    width: '21.33px',
    height: '20px',
    cursor: 'pointer',
  },
};

export const WelcomeSignUpTextTypography = styled(Typography)({
  color: '#1E293B',
  width: '384px',
  marginTop: '48px',
  lineHeight: '40px',
  fontFamily: 'Inter',
  fontSize: '30px',
  textAlign: 'left',
  'font-weight': 700,
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

export const MainContainerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '130px 0 128px 0',
  background: `url(${wholeBackgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height:'100vh',

  "@media only screen and (max-width: 414px)": {
    padding: '85px 0 148px 0',
    background: `url(${wholeBackgroundImageforMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

export const PasswordConditionWrapper = styled(Typography)({
  width: '384px',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '6px',
  "@media only screen and (max-width: 414px)": {
    width: '327px',
  },
});

export const PasswordConditionTitleTypograpy = styled(Typography)({
  fontSize: '14px',
  fontWeight: 700,
  fontfamily: 'Inter',
  color: '#334155',
  margin: 'auto',
  marginTop: '16px',
  marginBottom: '8px',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
  "@media only screen and (max-width: 414px)": {
    width: '327px',
  },
});

export const PasswordConditionDescTypograpy = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  color: '#334155',
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
  },
});

export const PolicyWrapper = styled(Box)({
  width: '384px',
  margin: '24px auto auto auto',
  display: 'flex',
  alignItems: 'start',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  color: '#1E293B',
  "@media only screen and (max-width: 414px)": {
    width: '327px',
  },
});

export const CreateAccountButton = styled(Button)({
  backgroundColor: '#395169',
  width: '384px',
  height: '56px',
  borderRadius: '8px',
  marginTop: '32px',
  color: '#FFFFFF',
  fontSize: '16px',
  fontFamily: 'Inter',
  boxShadow: 'none',
  textTransform: 'none',
  'font-weight': '700',
  '&:hover': {
    backgroundColor: '#395169',
    boxShadow: 'none',
  },
  "@media only screen and (max-width: 414px)": {
    width: '327px',
  },
});

export const AlreadyHaveAccountWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',
  fontSize: '16px',
  color: '#334155',
  'font-weight': 400,
  fontFamily: 'Inter',
  "@media only screen and (max-width: 414px)": {
    justifyContent: 'center',
    fontSize: '14px',
  },
});

export const LoginTextTypography = styled(Typography)({
  color: '#395169',
  marginLeft: '8px',
  fontSize: '16px',
  'font-weight': 700,
  cursor: 'pointer',
  fontFamily: 'Inter',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
});

// Customizable Area End

